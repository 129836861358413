import * as React from "react";
import SEO from "../components/SEO";
import Header from "../components/header";
import Newsletter from "../components/newsletter";
import Footer from "../components/Footer";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect.png";
import TunisiaJobs from "../assets/img/marketing.jpg";
import TunisiaJobs15 from "../assets/img/marketing_1.jpg";
import TunisiaJobs16 from "../assets/img/marketing_2.jpg";
import TunisiaJobs17 from "../assets/img/marketing_3.jpg";
import Arrow from "../themes/custom/jobs_front/assets/img/arrowRight2.png";

const Marketing = () => {
  return (
    <>
      <SEO title="Marketing | Visit Tunisia Project" />
      <Header />
      <main role="main">
        <div className="container-fluid">
          <div className="region region-banner">
            <div
              id="block-assistancetoentreprises"
              className="block block-block-content block-block-contentbbbe56a9-7411-4d30-b6b6-041089750357"
            >
              <div className="intern-banner">
                <div className="overlay">
                  <img src={BannerEffect} alt="" />
                </div>
                <img src={TunisiaJobs} alt="" />
                <div className="caption">
                  <h1 className="animate__animated animate__fadeInUp animate__slow">
                    <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                      Marketing & Communications
                    </div>
                  </h1>
                  <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                    <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                      <p>
                        Promoting Tunisia as a{" "}
                        <span> premier tourism destination.</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="region region-content">
            <div data-drupal-messages-fallback className="hidden" />
            <div
              id="block-jobs-front-content"
              className="block block-system block-system-main-block"
            >
              <article
                data-history-node-id={48}
                role="article"
                about="/en/Assistance_To_Enterprises"
                className="node node--type-activities node--promoted node--view-mode-full"
              >
                <div className="node__content">
                  <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                    <div>
                      <section className="sector-vision">
                        <div className="container">
                          <div className="row">
                            <div className="card-wrapper">
                              <div
                                className="card aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                              >
                                <div className="inner-card">
                                  <h3>THE SECTOR</h3>
                                  <p>
                                    Tourism is one of Tunisia’s most important
                                    industries, attracting more than 9 million
                                    visitors and contributing 13.1% of GDP in
                                    2019. With advanced marketing techniques
                                    that showcase the country’s full wealth of
                                    natural and cultural resources, the sector
                                    promises to grow even more profitable and
                                    inspire a new segment of local and
                                    international travelers.
                                  </p>
                                </div>
                              </div>
                              <div
                                className="card aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay
                              >
                                <div className="inner-card">
                                  <h3>VISIT TUNISIA’S VISION AND STRATEGY</h3>
                                  <p>
                                    The USAID Visit Tunisia Activity is helping
                                    to promote Tunisia as a high-quality tourist
                                    destination with diverse offerings,
                                    increasing the number of tourists year-round
                                    and creating new source markets. Visit
                                    Tunisia works with the government and
                                    private sector partners to improve Tunisia’s
                                    branding and marketing strategy, including
                                    by providing marketing training and
                                    assistance to enterprises and launching
                                    targeted tourism campaigns that showcase the
                                    country’s unique cultural, archeological,
                                    and adventure assets.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="resources" id="grants">
                        <div className="container">
                          <h2
                            className="title-left aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                              Our Work
                            </div>
                          </h2>
                          <div
                            className="section-paragh aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="d-flex flex-md-row flex-column">
                              <div className="left-side">
                                <p>
                                  Visit Tunisia partners with government
                                  agencies, private enterprises, tourism
                                  associations, destination marketing agencies,
                                  and content creators to market Tunisia as a
                                  compelling tourism destination.
                                </p>
                              </div>
                              <div className="right-side d-flex justify-content-md-end">
                                <div className="ticket">
                                  <h3>
                                    <a href="/where-we-work" target="_blank">
                                      Where We Work
                                    </a>
                                  </h3>
                                  <a
                                    className="underlined-link"
                                    href="/where-we-work"
                                    target="_blank"
                                  >
                                    Learn More{" "}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="grants-section"
                            style={{ marginTop: "25px", height: "60%" }}
                          >
                            <div className="d-flex flex-wrap">
                              <div
                                className="item work-card aos-animate our-work-image"
                                style={{ position: "relative" }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs15}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>Strategic Marketing Support</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We deliver financial/creative support and
                                      technical assistance to tourism
                                      associations, destination marketing
                                      organizations (DMOs), and other tourism
                                      operators to improve their marketing and
                                      communications strategies.
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={100}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs16}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>National Marketing Campaigns</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We work with the Ministry of Tourism and
                                      the ONTT to develop and implement 9
                                      national marketing campaigns that aim to
                                      increase arrivals and visitor spending in
                                      the underserved regions of the country.
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs17}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>Public Awareness Campaigns</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We develop and implement campaigns
                                      designed to improve the enabling
                                      environment for tourism by encouraging
                                      resident support to tourism development,
                                      changing perceptions and attitudes and
                                      creating and reinforcing positive
                                      behavior.{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs17}
                                  alt=""
                                  style={{ filter: "brightness(65%)" }}
                                />
                                <div className="card-text">
                                  <h3>Destination/Regional Marketing</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We provide technical assistance, market
                                      research, and creative support to help
                                      targeted destinations create and/or
                                      strengthen private public partnership
                                      destination management and marketing
                                      efforts.{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* <section
                        className="impact aos-animate"
                        data-aos="fade-up"
                      >
                        <div className="brick brick--type--impact brick--id--54 eck-entity container">
                          <span className="title-left">
                            <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                              OUR GOALS
                            </div>
                          </span>
                          <h2></h2>
                          <ul className="pl-0">
                            <li>
                              <span>
                                Increase social media views of Tunisia tourism
                                market by 20%
                              </span>
                            </li>

                            <li>
                              <span>
                                Increase sales of businesses promoted through
                                marketing campaigns by 10%.
                              </span>
                            </li>

                            <li>
                              <span>
                                Promote brand awareness about Tunisia as a
                                diverse tourism destination
                              </span>
                            </li>
                            <li>
                              <span>
                                Increase tourism visitor arrivals by 20%
                              </span>
                            </li>
                          </ul>
                        </div>
                      </section> */}
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </main>
      <Newsletter />
      <Footer />
    </>
  );
};
export default Marketing;
